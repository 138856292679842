/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useAppState } from "@state";
import { Meta } from "@atoms";

const PageContainer = ({ children, className, path, meta }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();

  const variants = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.main
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      }}
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className={clsx(className, "min-h-screen overflow-hidden")}
    >
      {children}
      {meta && <Meta {...meta} />}
    </motion.main>
  );
};

export default PageContainer;
