import React from "react";
import { Text, Video } from "@atoms";

const CbVideo = ({ videoUrl, caption }) => {
  return (
    <div className="my-6 md:my-10">
      <Video url={videoUrl} />
      {caption && (
        <Text variant="xs" className="mt-2 text-peach">
          {caption}
        </Text>
      )}
    </div>
  );
};

export default CbVideo;
