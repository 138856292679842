/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useMemo } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { imageUtils } from "@utils";
import useWindowSize from "@hooks/useWindowSize";
import { useInView } from "framer-motion";

const { buildUrl, defaultParams } = imageUtils;

const Image = ({
  image,
  ixParams,
  objectFit = "cover",
  caption,
  alt,
  backgroundColor,
  eager,
  fill,
  aspectRatio,
  widthAdjust = 0.8,
  preserveAspectRatio,
  draggable = true,
  className,
  singleSize,
}) => {
  if (!image) return null;

  const figure = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  const [loaded, setLoaded] = useState(eager);

  const isInView = useInView(figure, {
    once: true,
    margin: "-40px 0px 20px 0px",
    amount: "some",
  });

  const { url, height, width, fpx, fpy, skipBuild, hasFocalPoint } =
    image || {};

  const arm = aspectRatio ? aspectRatio[1] / aspectRatio[0] : 0;
  const ar = preserveAspectRatio || arm ? arm || width / height : 0;

  const finalParams = useMemo(() => {
    const params = hasFocalPoint
      ? {
          ...defaultParams(eager, ar, 1.5),
          "fp-x": fpx,
          "fp-y": fpy,
          "fp-z": 1,
          // "fp-debug": hasFocalPoint,
          fit: "crop",
          crop: "focalpoint",
        }
      : defaultParams(eager, ar, 1.5);

    return { ...params, ...ixParams };
  }, []);

  const baseUrl = !skipBuild
    ? buildUrl(url, {
        ...finalParams,
        w: parseInt(400 * widthAdjust, 10),
        h: parseInt(arm ? arm * 400 * widthAdjust : 400 * widthAdjust, 10),
      })
    : url;

  const sources = useMemo(() => {
    return (singleSize ? [1600] : [800, 1360, 2000]).map(size => ({
      url: !skipBuild
        ? buildUrl(url, {
            ...finalParams,
            w: parseInt(size * widthAdjust, 10) || size,
            h:
              parseInt(
                arm ? arm * size * widthAdjust : size * widthAdjust,
                10
              ) || size,
          })
        : url,
      size,
    }));
  }, [url, windowSize]);

  return (
    <>
      {eager && (
        <Helmet>
          <link rel="preload" as="image" href={baseUrl} />
        </Helmet>
      )}
      <figure
        ref={figure}
        className={clsx(
          className,
          "flex w-full flex-col overflow-hidden transition duration-200",
          { "absolute inset-0": fill, relative: !fill }
        )}
      >
        <div
          className={clsx("w-full transition duration-300", {
            "absolute inset-0": fill,
            "opacity-100": isInView,
            "opacity-0": !isInView,
          })}
          style={{
            backgroundColor,
            paddingTop: 0,
            aspectRatio: fill || !preserveAspectRatio ? null : ar,
          }}
        >
          <picture>
            {/* load srcset */}
            {(loaded || eager) && (
              <>
                {sources.map((s, i) => (
                  <source
                    key={s.size || 4000}
                    srcSet={s.url}
                    media={`(max-width: ${sources?.[i + 1]?.size || 4000}px)`}
                  />
                ))}
              </>
            )}
            {/* todo: fix alt tag fallback */}
            <img
              width="100%"
              height="100%"
              onLoad={!eager ? () => setLoaded(true) : null}
              src={baseUrl}
              loading={eager ? "eager" : "lazy"}
              draggable={draggable}
              alt={alt || "image"}
              className={clsx("absolute inset-0 h-full w-full", {
                "object-cover": objectFit === "cover",
                "object-contain": objectFit === "contain",
              })}
            />
          </picture>
        </div>
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    </>
  );
};

export default Image;
