import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  icon,
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={clsx(
        "inline-flex items-center underline decoration-pink decoration-2 underline-offset-4 hover:decoration-orange group-hover:decoration-orange",
        _className
      )}
    >
      {/* back arrow */}
      {icon && reverse && (
        <span
          className={clsx(
            "icon flex-shink-0 mr-1 h-4 w-4 rotate-90 transform",
            {}
          )}
        >
          <Icon
            name={icon || "chevron"}
            className="h-2.5 w-2.5 text-text-color"
          />
        </span>
      )}
      {/* label */}
      <span>{children}</span>
      {/* forward arrow */}
      {icon && !reverse && (
        <span className="icon flex-shink-0 ml-1 h-4 w-4 -rotate-90 text-text-color">
          <Icon
            name={icon || "chevron"}
            className="h-2.5 w-2.5 text-text-color"
          />
        </span>
      )}
    </AppLink>
  );
};

export default ButtonAlt;
