const resolveImage = _image => {
  const image = Array.isArray(_image) ? _image?.[0] : _image;
  if (image) {
    const {
      uid,
      status,
      title,
      url,
      height,
      width,
      hasFocalPoint,
      focalPoint,
      alt,
    } = image;

    return {
      uid,
      status,
      title,
      url,
      height,
      width,
      alt,
      hasFocalPoint,
      fpx: hasFocalPoint ? focalPoint?.[0] : false,
      fpy: hasFocalPoint ? focalPoint?.[1] : false,
    };
  }
  return null;
};

export default resolveImage;
