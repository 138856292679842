const plugin = require("tailwindcss/plugin");
const typography = require("@tailwindcss/typography");

const colors = {
  black: { DEFAULT: "#080609" },
  purple: { DEFAULT: "#3A2ABF" },
  pink: { DEFAULT: "#DA328A" },
  lavender: { DEFAULT: "#EBB1F4" },
  peach: { DEFAULT: "#FF6A6A" },
  orange: {
    DEFAULT: "#FF5700",
    transparent: "rgba(255,87,0,0.5)",
    gradient: "#ED4543",
  },
  "sky-blue": { DEFAULT: "#70E2F7" },
  "off-white": { DEFAULT: "#D4DCDC" },
  white: { DEFAULT: "#fff" },
  red: {
    DEFAULT: "#FF634E",
    dark: "#4f312f",
    light: "#ffe0dc",
  },
  "text-color": `var(--theme-text-color)`,
  highlight: `var(--theme-hightlight-color)`,
  "background-color": `var(--theme-bg-color)`,
};

module.exports = {
  colors,
  mode: "jit",
  content: ["./src/**/*.{js,svg,pcss}"],
  safelist: [
    "text-l-blue-to-pink",
    "text-l-pink-to-orange",
    "text-l-pink-to-purple",
    "text-orange",
    "text-peach",
    "text-lavender",
    "text-pink",
    "text-sky-blue",
  ],
  theme: {
    fontFamily: {
      header: ["Power Grotesk", "sans-serif"],
      sans: ["Gilmer", "sans-serif"],
    },
    fontSize: {
      xxs: ".625rem",
      xs: ".666rem",
      sm: ".76rem",
      base: "0.85rem",
      lg: "0.95rem",
      xl: "1.05rem",
      "1.5xl": "1.43rem",
      "2xl": "1.6rem",
      "2.5xl": "1.825rem",
      "3xl": "2rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      "11xl": "9rem",
      "12xl": "10rem",
      h1: "4.9rem",
      h2: "4rem",
      h3: "3.04rem",
      h4: "2.47rem",
      h5: "1.7rem",
      h6: "1.14rem",
      h7: "0.9rem",
      inherit: "inherit",
    },
    screens: {
      xxs: { max: "350px" }, // for super small screens
      sm: "700px", // bigger than most phones
      md: "850px",
      lg: "1200px",
      xl: "1400px", // larger than 15" macbook pro
      xxl: "2000px",
      tall: {
        raw: "(min-height: 820px)",
      },
    },
    extend: {
      colors,
      maxWidth: {
        xs: "20rem",
        sm: "24rem",
        md: "28rem",
        lg: "32rem",
        xl: "36rem",
        "2xl": "44rem",
        "3xl": "52rem",
        "4xl": "60rem",
        "5xl": "68rem",
        "6xl": "76rem",
        "7xl": "84rem",
        "8xl": "92rem",
        full: "100%",
      },
      transitionProperty: {
        default:
          " background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, box-shadow",
      },
      opacity: {
        10: ".1",
        15: ".15",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".9",
        90: ".9",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "7/8-screen": "87.5vh",
      },
      height: {
        25: "25%",
        "1/2-screen": "50vh",
      },
      width: {
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
      },
      typography: theme => ({
        DEFAULT: {
          css: {
            ol: {
              listStyleType: "decimal-leading-zero",
            },
            "ol > li::marker": {
              fontWeight: "500",
              color: theme("colors.pink.DEFAULT"),
            },
            "--tw-prose-body": "var(--theme-text-color)",
            "--tw-prose-headings": "var(--theme-text-color)",
            "--tw-prose-lead": "var(--theme-text-color)",
            "--tw-prose-links": "var(--theme-text-color)",
            "--tw-prose-bold": "inherit",
            "--tw-prose-counters": theme("colors.black.DEFAULT"),
            "--tw-prose-bullets": theme("colors.pink.DEFAULT"),
            "--tw-prose-hr": theme("colors.gray.DEFAULT"),
            "--tw-prose-quotes": "var(--theme-text-color)",
            "--tw-prose-quote-borders": theme("colors.pink.DEFAULT"),
            "--tw-prose-captions": "var(--theme-text-color)",
            "--tw-prose-code": "var(--theme-text-color)",
            "--tw-prose-pre-code": "var(--theme-text-color)",
            "--tw-prose-pre-bg": "var(--theme-text-color)",
            "--tw-prose-th-borders": "transparent",
            "--tw-prose-td-borders": "transparent",
            ":where(blockquote p)::before": {
              content: "none !important",
            },
            fontSize: "0.85rem",
            fontWeight: "400",
            lineHeight: 1.5,
            p: {
              margin: "0 0 1.5rem",
            },
            "h2, h3, h4, h5": {
              margin: " 0 0 1.125rem",
              fontFamily: theme("fontFamily.header").join(", "),
              lineHeight: 1.125,
            },
            a: {
              textDecorationThickness: "2px",
              textDecorationColor: theme("colors.pink.DEFAULT"),
              textUnderlineOffset: "4px",
              fontWeight: "inherit",
              fontSize: "inherit",
              "&:hover": {
                textDecorationColor: theme("colors.orange.DEFAULT"),
              },
            },
          },
        },
      }),
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
      backgroundImage: {
        "radial-gradient": "radial-gradient(var(--tw-gradient-stops))",
        "spotlight-text": "url('/images/spotlight-text.svg')",
      },
      flex: {
        "1/2-4": "1 1 calc(50%-1rem)",
      },
      boxShadow: {
        glow: "0 0 1rem var(--tw-shadow-color)",
      },
      transitionTimingFunction: {
        "out-expo": "cubic-bezier(0.16, 1, 0.3, 1)",
        "in-out-expo": "cubic-bezier(0.87, 0, 0.13, 1)",
        "in-expo": "cubic-bezier(0.7, 0, 0.84, 0)",
        "in-out-quad": "cubic-bezier(0.45, 0, 0.55, 1)",
      },
    },
  },
  plugins: [
    typography,
    plugin(({ addUtilities, e, theme, variants }) => {
      Object.entries(theme("gap")).forEach(([key, value]) =>
        addUtilities(
          {
            [`.flex-gap-${e(key)}`]: {
              marginTop: `-${value}`,
              marginLeft: `-${value}`,
              "& > *": {
                paddingTop: value,
                paddingLeft: value,
              },
            },
          },
          variants("gap")
        )
      );
    }),
  ],
};
